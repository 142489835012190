import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";

export const UnterschreibenPageTemplate = ({
  title,
  content,
  contentComponent,
  image,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <section
        className="section hero has-overlay section--gradient background--image background--fixed has-text-white level"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
        }}
      >
        <div className="container level">
          <div className="section">
            <div className="columns">
              <div className="column ">
                <h1 className="title has-text-white">
                  {title}
                  <b></b>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <div className="section">
                <PageContent className="content" content={content} />

                <h2 className="title" id="sammelstellen">
                  Sammelstellen
                </h2>
                <p>Wir danken allen Sammelstellen und weiteren Unterstützern, die uns bei der Durchführung des Bergerbegehrems geholfen haben!</p>
                <br></br>

                <div className="columns">
                  <div className="column is-6">
                    <h2 className="title">Unterschriftenliste</h2>
                    <p className="">
                      Hier kannst Du die Unterschriftenzettel des Bürgerbegehrens runterladen.
                    </p>
                    <br></br>
                    <a
                      className="button is-large is-primary"
                      href="https://drive.google.com/file/d/1JUC1FMwshDp5tvEUDnJnM_Yi77AZUTeV/view?usp=sharing"
                      target="__blank"
                    >
                      Download Hier!
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

UnterschreibenPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const UnterschreibenPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <UnterschreibenPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
      />
      <Helmet titleTemplate="%s | Radentscheid Bayreuth">
        <title>{`${post.frontmatter.title}`}</title>
        <meta name="description" content={`${post.frontmatter.description}`} />
      </Helmet>
    </Layout>
  );
};

UnterschreibenPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UnterschreibenPage;

export const unterschreibenPageQuery = graphql`
  query UnterschreibenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
